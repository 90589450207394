import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';

const MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY;
const PLACES_AUTOCOMPLETE = 'PLACES_AUTOCOMPLETE';

function PlacesAutocomplete({ formik, label, disabled }) {
  const inputRef = useRef(null);
  const [ready, setReady] = useState(false);
  const [bypassDisableEmptyField, setBypassDisableEmptyField] = useState(true);

  useEffect(() => {
    setBypassDisableEmptyField(!formik.values.address);
    const script = document.getElementById(PLACES_AUTOCOMPLETE);
    if (window.google || script) return;
    const scriptTag = document.createElement('script');
    scriptTag.id = PLACES_AUTOCOMPLETE;
    scriptTag.async = true;
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&libraries=places`;
    scriptTag.onload = () => {
      setReady(true);
    };
    document.head.appendChild(scriptTag);
    return () => {
      document.head.removeChild(scriptTag);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current && window.google) {
      var autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          fields: ['formatted_address', 'address_components'],
          componentRestrictions: { country: 'us' },
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const state =
          place.address_components.find((component) =>
            component.types.includes('administrative_area_level_1')
          )?.short_name || '';
        const city =
          place.address_components.find((component) =>
            component.types.includes('locality')
          )?.long_name || '';
        const country =
          place.address_components.find((component) =>
            component.types.includes('country')
          )?.short_name || '';
        const zipCode =
          place.address_components.find((component) =>
            component.types.includes('postal_code')
          )?.long_name || '';
        // Borough is added to handle NYC addresses.
        const borough =
          place.address_components.find((component) =>
            component.types.includes('sublocality_level_1')
          )?.long_name || '';
        formik.setFieldValue(
          'address',
          place.formatted_address.split(',')[0],
          true
        );
        formik.setFieldValue('city', city || borough, true);
        formik.setFieldValue('state', state, true);
        formik.setFieldValue('country', country, true);
        formik.setFieldValue('zipCode', zipCode, true);
      });
    }
  }, [ready, formik]);

  return (
    <TextField
      disabled={disabled && !bypassDisableEmptyField}
      fullWidth
      sx={{ mb: 3 }}
      inputProps={{
        ref: inputRef,
      }}
      label={label}
      value={formik.values.address}
      onChange={formik.handleChange}
      name="address"
      variant="outlined"
      error={Boolean(formik.touched.address && formik.errors.address)}
      helperText={formik.touched.address && formik.errors.address}
    />
  );
}
export default React.memo(PlacesAutocomplete);
