import React, { KeyboardEventHandler, useState } from 'react';
import { TextFieldProps } from '@mui/material';
import { numberFormatter } from '../../utils/number-formatter';
import { TextField } from '@mui/material';
import { DollarSignIcon } from '../../icons/dollar-sign';

const formatInput = (raw: string) => {
    if (raw === '') return raw;
    if (raw.includes('.')) {
        const [intPart, decimalPart] = raw.split('.');
        return `${numberFormatter.format(Number(intPart))}.${decimalPart}`;
    }
    return numberFormatter.format(Number(raw));
};

type CurrencyFieldProps = Omit<TextFieldProps, 'onChange'> & {
    onChange: (value: string, raw: string) => void;
    value: string;
};

export const CurrencyField = (props: CurrencyFieldProps) => {
    const { onChange, value: inputValue } = props;
    const [rawInput, setRawInput] = useState<string>(
        inputValue.replace(/[,]/g, '')
    );

    const handleCurrencyChange: KeyboardEventHandler = (event) => {
        const { key } = event;
        let raw = rawInput || '';

        if (key === 'Backspace') {
            const { selectionStart, selectionEnd } = event.target as HTMLInputElement;
            if (
                selectionStart !== null &&
                selectionEnd !== null &&
                selectionStart !== selectionEnd
            ) {
                const updatedValue =
                    inputValue.slice(0, selectionStart) + inputValue.slice(selectionEnd);
                raw = updatedValue.replace(/[,]/g, '');
            } else {
                raw = raw.substring(0, raw.length - 1);
                if (raw[raw.length - 1] === '.') {
                    raw = raw.substring(0, raw.length - 1);
                }
            }
        } else if (!raw.split('.')[1] || raw.split('.')[1].length < 2) raw += key;
        if (Number.isNaN(Number(raw))) return;
        onChange(formatInput(raw), raw);
        setRawInput(raw);
    };

    return (
        <TextField
            {...props}
            InputProps={{
                startAdornment: <DollarSignIcon />,
            }}
            onChange={() => null}
            onKeyDown={handleCurrencyChange}
        />
    );
};
