import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { v4 as uuid } from 'uuid';

export default function ImageUploader({ title, subtitle, files, setFiles }) {
  const [selectedRows, setSelectedRows] = useState([]);

  const deleteSelectedRow = () => {
    const filteredFiles = files.filter((_, i) => !selectedRows.includes(i));
    const filteredIndexes = selectedRows.filter(
      (_, i) => !selectedRows.includes(i)
    );
    setFiles(filteredFiles);
    setSelectedRows(filteredIndexes);
  };

  const handleFileUpload = (e) => {
    if (!e.target.files?.[0]?.name) {
      return;
    }
    const file = e.target.files[0];

    setFiles((prev) => {
      const prevCopy = [...prev];
      prevCopy.push(file);
      return prevCopy;
    });
  };
  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            sx={{ justifyContent: { xs: 'flex-start', lg: 'flex-end' } }}
          >
            <Button
              type="button"
              title="borrar"
              onClick={deleteSelectedRow}
              disabled={!selectedRows.length}
            >
              <DeleteOutlineIcon />
            </Button>
            <Button component="label" startIcon={<FileUploadIcon />}>
              Upload{' '}
              <input
                type="file"
                accept=".jpg,.jpeg,.heic,.pdf,.png,.gif"
                hidden
                onChange={handleFileUpload}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Typography variant="body1">{subtitle}</Typography>
      </Grid>
      {files.map((file, index) => {
        const uid = uuid();
        return (
          <Grid key={uid} item xs={12} sx={{ mb: 3 }}>
            <Grid container>
              <Grid
                item
                xs={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Checkbox
                  checked={selectedRows.includes(index)}
                  onChange={() => {
                    setSelectedRows((prev) => {
                      const newSelectedRows = [...prev];
                      if (newSelectedRows.includes(index)) {
                        newSelectedRows.splice(
                          newSelectedRows.indexOf(index),
                          1
                        );
                      } else {
                        newSelectedRows.push(index);
                      }
                      return newSelectedRows;
                    });
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Grid item xs={12}>
                  <TextField
                    aria-readonly
                    fullWidth
                    label="File"
                    variant="outlined"
                    value={file.name}
                    inputProps={{
                      readOnly: true,
                      style: {
                        color: '#3E8682',
                        textDecoration: 'underline',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
