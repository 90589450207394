export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const currencyFormatterInteger = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
});
