import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { graphql, Link, navigate } from 'gatsby';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/material';
import { theme } from '../theme';
import Layout from '../components/layout/layout';
import Markdown from '../components/markdown';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import useFormGenerator from '../hooks/useFormGenerator';
import { useFileUploader, signUp } from '../hooks/useFirebase';
import { submitClaim, submitClaimForHashing } from '../services/claims.service';
import { useClaimStatus, useSnackbar } from '../store';
import { SnackBar } from '../components/snackbar';
import { certificationBlock } from '../../markdownSources';
import logAnalyticsEvent from '../utils/log-analytics-event';

const claimItemNumberHandler = (claimType, unitsNonPriced, units) => {
  if (claimType === 'non-priced-pop') return Math.abs(unitsNonPriced);
  if (claimType === 'priced-pop') {
    switch (units) {
      case 'one':
        return 1;
      case 'two':
        return 2;
      case 'three':
        return 3;
      case 'four':
        return 4;
      case 'five-plus':
        return 5;
      default:
        return 1;
    }
  }

  return 1;
};

const ClaimForm = ({ data }) => {
  useEffect(() => {
    logAnalyticsEvent('claim_form_page_visit', {
      caseId: data?.template?.caseID,
    });
  }, []);
  const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY;
  const { caseName, caseID } = data?.template;

  const { openSnackbar } = useSnackbar();
  const { setClaimID } = useClaimStatus();
  const fileUploader = useFileUploader();
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));

  const [files, setFiles] = useState([]);
  const [legalRepresentativeFiles, setLegalRepresentativeFiles] = useState([]);
  const [captcha, setCaptcha] = useState();
  const [loading, setLoading] = useState(false);

  const submitUndefinedPurchaserClaim = async (request, headers) => {
    const {
      data: { orderID, key },
    } = await submitClaimForHashing({ job: request }, headers);
    await signUp(`${orderID}${caseID}@claimscore.ai`, key);
    await submitClaim({ job: request, orderID, key });
    return { data: { orderID } };
  };

  const [
    formGenerator,
    submitHandler,
    updateInitialValues,
    generateCertificate,
  ] = useFormGenerator({
    onSubmit: async (values) => {
      try {
        setLoading(true);

        if (
          ['priced-pop', 'non-priced-pop'].includes(values.claimType) &&
          files.length === 0
        ) {
          openSnackbar('Please upload your Proof of Purchase', 'error');
          setLoading(false);
          return;
        }
        const fileUploads = files.map(async (file) => {
          const fileUrl = `/pop/pending/${caseID}/${uuid()}.${
            file.name.split('.')[1]
          }`;
          return fileUploader(fileUrl, file).then(
            (snapshot) => snapshot.metadata.fullPath
          );
        });

        let popFileUrls;

        try {
          popFileUrls = await Promise.all(fileUploads);
        } catch (error) {
          openSnackbar(
            'There was an error uploading the files.  Please try reloading the page.',
            'error'
          );
          setLoading(false);
          return;
        }

        const additionalDataProducts = [...values.products];
        values.userSpecifiedProduct &&
          additionalDataProducts.push(values.userSpecifiedProduct);

        const getProductData = () => {
          const popLinks = popFileUrls || [];
          const claimType = values.claimType === 'non-pop' ? 'Non-PoP' : 'PoP';
          switch (values.claimType) {
            case 'non-pop':
              return {
                date: values.purchaseDate
                  ? dayjs(values.purchaseDate).format('MM/DD/YYYY')
                  : '',
                storeName: values.storeLocation.name || '',
                claimType,
              };
            case 'priced-pop':
              return {
                price: values.paidAmount || '',
                popLinks,
                claimType,
              };
            case 'non-priced-pop':
              return {
                totalUnits: claimItemNumberHandler(
                  values.claimType,
                  values.unitsNonPriced,
                  values.units
                ),
                popLinks,
                claimType,
              };
          }
        };

        const request = {
          caseID,
          caseName,
          claimSearch: {
            orderDate: new Date().toISOString(),
            billingFirstName: values.firstName,
            billingLastName: values.lastName,
            billingStreet: values.address,
            billingStreet2: values.address2,
            billingCity: values.city,
            billingState: values.state,
            billingZip: values.zipCode,
            phone: values.phone,
            email: values.email,
            claimItemType: values.claimType === 'non-pop' ? 'Non-PoP' : 'PoP',
            claimItemNumber: claimItemNumberHandler(
              values.claimType,
              values.unitsNonPriced,
              values.units
            ),
            popLinks: popFileUrls || [],
            referrer: document.referrer,
            productData: getProductData(),
            additionalData: {
              purchaserId: values.purchaserId,
              products: additionalDataProducts,
              sellerType: values.sellerType,
              units: values.units,
              paidAmount: values.paidAmount,
              acceptedPrivacy: values.acceptPrivacy,
              todayDate: values.date
                ? dayjs(values.date).format('MM/DD/YYYY')
                : '',
              date: dayjs(values.date).format('MM/DD/YYYY'),
              phone2: values.phone2,
              claimantFullName: values.claimantFullName,
              certified: values.certified,
            },
          },
        };

        const headers = {
          'x-recaptcha-token': captcha,
        };

        const data = await submitUndefinedPurchaserClaim(request, headers);

        const { orderID } = data;
        setClaimID(orderID);
        logAnalyticsEvent('claim_form_submit_success', {
          claimId: orderID,
          caseId: caseID,
        });
        navigate('/claim-check');
        // TODO: check that orderID is returned and captcha is sent
        return;
      } catch (e) {
        logAnalyticsEvent('claim_form_submit_error', { caseId: caseID });
        const errorMessage =
          'Experiencing an unexpected error. Please try reloading the page.';
        openSnackbar(errorMessage, 'error');
        setLoading(false);
      }
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <LoadingOverlay open={loading} />
      <Layout>
        <SnackBar>
          <form onSubmit={submitHandler}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
                Submit a Claim
              </Typography>
            </Box>
            <Box sx={{ mb: 8 }}>
              <Breadcrumbs separator="→" aria-label="breadcrumb">
                {[
                  <Link key="1" to="/">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '16px',
                          md: '32px',
                          color: 'neutral.500',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Home
                    </Typography>
                  </Link>,
                  Array.isArray(data.template.instructions) &&
                    data.template.instructions.length > 0 && (
                      <Link key="2" to="/claim-instructions">
                        <Typography
                          sx={{
                            fontSize: {
                              xs: '16px',
                              md: '32px',
                              color: 'neutral.500',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          Claim Instructions
                        </Typography>
                      </Link>
                    ),
                  <Typography
                    key="3"
                    sx={{ fontSize: { xs: '16px', md: '32px' } }}
                  >
                    Claim Form
                  </Typography>,
                ]}
              </Breadcrumbs>
            </Box>
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  General Instructions
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item>
                    <Stack direction="column" spacing={3}>
                      <Typography variant="primary">
                        <Typography sx={{ fontWeight: 600 }} display="inline">
                          Settlement Class Members who seek payment from the
                          Settlement must complete and return this Claim
                          Form.&nbsp;
                        </Typography>
                        Completed Claim Forms must be mailed to the Settlement
                        Administrator at AC2T Settlement Administrator, PO Box
                        231, Valparaiso, IN 46384, or can be submitted via the
                        Settlement Website, www.AC2TSettlement.com.&nbsp;
                        <Typography variant="primary" sx={{ fontWeight: 600 }}>
                          Claim Forms must be POSTMARKED OR SUBMITTED ONLINE NO
                          LATER THAN DECEMBER 1, 2023 at 11:59 pm, eastern time.
                        </Typography>
                      </Typography>
                      <Typography variant="primary">
                        Before you complete and submit this Claim Form by mail
                        or online, you should read and be familiar with the
                        Notice of Proposed Class Action Settlement (“the
                        Notice”) available at www.AC2TSettlement.com. Defined
                        terms (with initial capitals) used in these General
                        Instructions have the same meaning as set forth in the
                        Notice. By submitting this Claim Form, you acknowledge
                        that you have read and understand the Notice, and you
                        agree to the Release included as a material term of the
                        Settlement Agreement.
                      </Typography>
                      <Typography variant="primary">
                        If you fail to submit a timely Claim Form, your Claim
                        will be rejected and you will be precluded from any
                        recovery from the Settlement fund. If you are a member
                        of the Settlement Class and you do not timely and
                        validly seek exclusion from the Settlement Class, you
                        will be bound by any judgment entered by the Court
                        approving the Settlement regardless of whether you
                        submit a Claim Form. You can elect one Benefit per
                        Household. To receive the most current information and
                        regular updates, please submit your Claim Form on the
                        Settlement Website at www.AC2TSettlement.com. On the
                        Settlement Website, you will also be able to submit your
                        web Claim.
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Claimant Information
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  {formGenerator(
                    files,
                    setFiles,
                    legalRepresentativeFiles,
                    setLegalRepresentativeFiles
                  )}
                </Grid>
                <Divider sx={{ mb: 3 }} />
                <Typography variant="body1" sx={{ paddingBottom: '12px' }}>
                  By signing below, you are submitting to the jurisdiction of
                  the Supreme Court of New York, Kings County.
                </Typography>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Certification under penalty of perjury
                </Typography>
                <Grid container sx={{ mt: 3 }}>
                  <Grid item>
                    <Markdown source={certificationBlock} />
                  </Grid>
                  {generateCertificate()}
                </Grid>
              </CardContent>
            </Card>

            {RECAPTCHA_KEY && (
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={(value) => {
                  setCaptcha(value);
                }}
              />
            )}
            <Box sx={{ mb: 2 }}>
              <Button
                type="submit"
                fullWidth={!isLG}
                variant="contained"
                sx={{
                  my: 2,
                  color: 'background.paper',
                  px: { md: '44px' },
                  py: 2,
                  fontSize: '15px',
                  fontWeight: 400,
                }}
                disableElevation
              >
                Submit Claim
              </Button>
            </Box>
          </form>
        </SnackBar>
      </Layout>
    </LocalizationProvider>
  );
};

export default ClaimForm;

export const Head = ({ data }) => (
  <title>ClaimForm | {data.template.caseName}</title>
);

export const query = graphql`
  query FormPage($id: StringQueryOperatorInput = {}) {
    caseConfiguration(id: $id) {
      billingCity {
        editable
        nameToShow
      }
      billingFirstName {
        editable
        nameToShow
      }
      billingLastName {
        editable
        nameToShow
      }
      billingState {
        editable
        nameToShow
      }
      billingStreet {
        editable
        nameToShow
      }
      billingStreet2 {
        editable
        nameToShow
      }
      billingZip {
        editable
        nameToShow
      }
      email {
        editable
        nameToShow
      }
      phone {
        editable
        nameToShow
      }
    }
    template(id: $id) {
      caseID
      caseName
      claimInfo {
        type
        wrapper
        inputs
        text
        group
        options {
          id
          description
          value
        }
      }
      instructions {
        type
      }
      authentication {
        loginForm
        idFieldName
        secretFieldName
      }
    }
  }
`;
