import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { DateField } from '@mui/x-date-pickers/DateField';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
  Checkbox,
  Stack,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'gatsby';
import { country_list } from '../constants';
import PlacesAutocomplete from '../components/places-autocomplete';
import ImageUploader from '../components/image-uploader';
import { CurrencyField } from '../components/currency-field';

export default function useFormGenerator({
  inputList,
  includeUniqueID,
  caseConfiguration,
  errorEmail,
  loginForm,
  authentication,
  onSubmit,
}) {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      phone: '',
      phone2: '',
      email: '',

      claimType: '',
      // notification: 'direct'
      uniqueId: '',
      pin: '',

      products: [],
      storeLocation: {
        name: '',
        city: '',
        state: '',
      },
      sellerType: '',
      units: '',
      unitsNonPriced: 0,
      paidAmount: '',
      purchaseDate: '',

      date: '',
      claimantFullName: '',

      certified: false,
      userSpecifiedProduct: '',
      acceptPrivacy: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().max(255).required('First Name is required'),
      lastName: Yup.string().max(255).required('Last Name is required'),
      address: Yup.string().max(255).required('Address is required'),
      address2: Yup.string().max(255),
      city: Yup.string().max(255).required('City is required'),
      state: Yup.string()
        .max(
          2,
          'State must be two characters long. Please use its abbreviated form.'
        )
        .required('State is required'),
      zipCode: Yup.string()
        .max(255)
        .required('Zip Code is required')
        .max(5, 'Zip Code can only be 5 digits long.'),
      phone: Yup.string()
        .max(255)
        .max(10, 'Phone number can only be 10 digits long'),
      phone2: Yup.string()
        .max(255)
        .max(10, 'Phone number can only be 10 digits long'),
      email: Yup.string().max(255).email().required('Email is required'),
      purchaserId: Yup.string().max(255),
      claimType: Yup.string()
        .oneOf(['non-pop', 'priced-pop', 'non-priced-pop'])
        .required('Please select what kind of proof of purchase you have'),
      products: Yup.array().when(['claimType'], {
        is: (type) => type && type !== 'direct',
        then: Yup.array().min(1, 'Please specify the product(s) you purchased'),
      }),
      storeLocation: Yup.object().when(['claimType'], {
        is: (type) => type === 'non-pop',
        then: Yup.object({
          name: Yup.string().max(255).required('Store name is required'),
          city: Yup.string().max(255).required('City is required'),
          state: Yup.string()
            .max(
              2,
              'State must be two characters long. Please use its abbreviated form.'
            )
            .required('State is required'),
        }),
      }),
      sellerType: Yup.string().when(['claimType'], {
        is: (type) => type === 'non-pop',
        then: Yup.string()
          .oneOf(['retailer', 'online-seller'])
          .required(
            'Please specify through which means you made your purchase'
          ),
      }),
      units: Yup.string().when(['claimType'], {
        is: (type) => type === 'priced-pop',
        then: Yup.string().required(
          'Please specify the amount of units you purchased'
        ),
      }),
      unitsNonPriced: Yup.number().when(['claimType'], {
        is: (type) => type === 'non-priced-pop',
        then: Yup.number()
          .integer()
          .min(1)
          .required('Please specify the amount of units you purchased'),
      }),
      paidAmount: Yup.string().when(['claimType'], {
        is: (type) => type === 'priced-pop',
        then: Yup.string().required(
          'Please specify the total amount paid on your purchase'
        ),
      }),
      purchaseDate: Yup.date().when(['claimType'], {
        is: (type) => type === 'non-pop',
        then: Yup.date()
          .required('Please add the date of your purchase')
          .test(
            'is-valid-date',
            'Invalid Date format',
            (value) => !isNaN(value)
          ),
      }),
      date: Yup.string().required(`Today's date is required`),
      claimantFullName: Yup.string()
        .max(255)
        .required(`Claimant's name is required`),
      certified: Yup.boolean().isTrue('Certification is required'),
      userSpecifiedProduct: Yup.string().when(['products'], {
        is: (products) => products && products.includes('other'),
        then: Yup.string().required('Please specify the product you purchased'),
      }),
      acceptPrivacy: Yup.boolean().isTrue('Must accept our privacy policy'),
    }),
    onSubmit,
  });

  const updateInitialValues = (_claimant) => {
    formik.setValues({
      firstName: _claimant?.billingFirstName,
      middleInitial: _claimant?.middleInitial,
      lastName: _claimant?.billingLastName,
      country: _claimant?.country,
      address: _claimant?.billingStreet,
      address2: _claimant?.billingStreet2,
      city: _claimant?.billingCity,
      state: _claimant?.billingState,
      zipCode: _claimant?.billingZip,
      phone: _claimant?.phone,
      email: _claimant?.email,
      certified: _claimant?.certified,
    });
  };

  const generateForm = useCallback(
    (
      files,
      setFiles,
      legalRepresentativeFiles,
      setLegalRepresentativeFiles
    ) => (
      <React.Fragment>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="First Name"
            name="firstName"
            variant="outlined"
            placeholder="John"
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label="Last Name"
            name="lastName"
            variant="outlined"
            placeholder="Doe"
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <PlacesAutocomplete label="Address Line 1" formik={formik} /> */}
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label={'Address Line 1'}
            value={formik.values.address}
            onChange={formik.handleChange}
            name="address"
            variant="outlined"
            error={Boolean(formik.touched.address && formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="City"
            name="city"
            variant="outlined"
            placeholder="Red Bank"
            value={formik.values.city}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.city && formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="State"
            name="state"
            variant="outlined"
            placeholder="NJ"
            value={formik.values.state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.state && formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label="Zip Code"
            name="zipCode"
            variant="outlined"
            placeholder="XXXXX"
            value={formik.values.zipCode}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Daytime Phone Number"
            name="phone"
            variant="outlined"
            placeholder="+1 (123) 555 - 0000"
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Evening Phone Number"
            name="phone2"
            variant="outlined"
            placeholder="+1 (123) 555 - 0000"
            value={formik.values.phone2}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Email"
            name="email"
            variant="outlined"
            placeholder="johndoe@email.com"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider fullWidth />
        </Grid>
        <Grid item xs={12} sx={{ my: 3 }}>
          <Typography variant="h6">Claim Type</Typography>
          <Box sx={{ mt: 3 }}>
            <FormControl
              error={Boolean(
                formik.touched.claimType && formik.errors.claimType
              )}
            >
              <RadioGroup
                aria-labelledby="claimType-radio-group-label"
                name="claimType"
                value={formik.values.claimType}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="non-pop"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.claimType && formik.errors.claimType
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.claimType === 'non-pop'}
                    />
                  }
                  label="No Proof of Purchase"
                />
                <FormControlLabel
                  value="priced-pop"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.claimType && formik.errors.claimType
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.claimType === 'priced-pop'}
                    />
                  }
                  label="Proof of Purchase with Price"
                />
                <FormControlLabel
                  value="non-priced-pop"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.claimType && formik.errors.claimType
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.claimType === 'non-priced-pop'}
                    />
                  }
                  label="Proof of Purchase without Price"
                />
              </RadioGroup>
              {Boolean(formik.touched.claimType && formik.errors.claimType) && (
                <FormHelperText>
                  {formik.touched.claimType && formik.errors.claimType}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
        {formik.values.claimType && formik.values.claimType === 'non-pop' && (
          <>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Tier 1 Claims
              </Typography>
              <Typography variant="body1">
                Tier 1 Benefit is available for Class Members who purchased
                Spartan Mosquito Products, including “Spartan Mosquito
                Eradicator” and “Spartan Mosquito Pro Tech,” during the Class
                Period and do not have a valid Proof of Purchase. You may
                receive up to a maximum of $7.00 per Box, up to 1 Box, although
                this amount may be reduced depending on the total number of
                claims received.
              </Typography>
            </Grid>
          </>
        )}
        {formik.values.claimType &&
          formik.values.claimType === 'priced-pop' && (
            <>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Tier 2 Claims
                </Typography>
                <Typography variant="body1">
                  Tier 2 Benefit is available for Class Members who purchased
                  Spartan Mosquito Products, including “Spartan Mosquito
                  Eradicator” and “Spartan Mosquito Pro Tech” during the Class
                  Period and have a valid Proof of Purchase that reflects the
                  actual purchase price paid for the product. You may receive
                  reimbursement for the full purchase price shown on the proof
                  of purchase for all boxes purchased, or less depending on a
                  number of factors including how many Valid Claims are
                  submitted.
                </Typography>
              </Grid>
            </>
          )}
        {formik.values.claimType &&
          formik.values.claimType === 'non-priced-pop' && (
            <>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Tier 3 Claims
                </Typography>
                <Typography variant="body1">
                  Tier 3 Benefit is available for Class Members who purchased
                  Spartan Mosquito Products, including “Spartan Mosquito
                  Eradicator” and “Spartan Mosquito Pro Tech” during the Class
                  Period and have an otherwise valid Proof of Purchase that does
                  not reflect the actual purchase price paid. You may receive
                  reimbursement for up to $10.00 per box for all boxes
                  purchased, or less depending on a number of factors including
                  how many Valid Claims are submitted.
                </Typography>
              </Grid>
            </>
          )}
        {formik.values.claimType && formik.values.claimType !== 'direct' && (
          <>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Purchase Information
              </Typography>
              <Typography variant="body1">
                1. Please identify the Spartan Mosquito Product(s) you
                purchased:
              </Typography>
            </Grid>
            <Grid item sx={{ mb: 3 }} display="flex" alignItems="center">
              <FormControl
                component="fieldset"
                error={Boolean(
                  formik.touched.products && formik.errors.products
                )}
                value={formik.values.products}
                onChange={formik.handleChange}
              >
                <FormGroup row>
                  <FormControlLabel
                    value="eradicator"
                    control={
                      <Checkbox
                        name="products"
                        sx={{
                          color:
                            formik.touched.products && formik.errors.products
                              ? 'error.main'
                              : 'unset',
                        }}
                      />
                    }
                    label="Eradicator"
                  />
                  <FormControlLabel
                    value="pro tech"
                    control={
                      <Checkbox
                        name="products"
                        sx={{
                          color:
                            formik.touched.products && formik.errors.products
                              ? 'error.main'
                              : 'unset',
                        }}
                      />
                    }
                    label="Pro Tech"
                  />
                  <FormControlLabel
                    value="other"
                    control={
                      <Checkbox
                        name="products"
                        sx={{
                          color:
                            formik.touched.products && formik.errors.products
                              ? 'error.main'
                              : 'unset',
                        }}
                      />
                    }
                    label="Other"
                  />
                </FormGroup>
                {Boolean(formik.touched.products && formik.errors.products) && (
                  <FormHelperText>
                    {formik.touched.products && formik.errors.products}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={true}>
              <TextField
                disabled={!formik.values.products.includes('other')}
                fullWidth
                sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
                label="If other, specify:"
                name="userSpecifiedProduct"
                variant="outlined"
                placeholder="Other"
                value={formik.userSpecifiedProduct}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.userSpecifiedProduct &&
                    formik.errors.userSpecifiedProduct
                )}
                helperText={
                  formik.touched.userSpecifiedProduct &&
                  formik.errors.userSpecifiedProduct
                }
              />
            </Grid>
          </>
        )}
        {(formik.values.claimType === 'non-priced-pop' ||
          formik.values.claimType === 'priced-pop') && (
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography variant="body1">
              2. How many units did you purchase?
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          {formik.values.claimType === 'priced-pop' && (
            <FormControl
              component="fieldset"
              error={Boolean(formik.touched.units && formik.errors.units)}
            >
              <RadioGroup
                row
                aria-labelledby="units-radio-group"
                name="units"
                value={formik.values.units}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="one"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.units && formik.errors.units
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.units === 'one'}
                    />
                  }
                  label="One"
                />
                <FormControlLabel
                  value="two"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.units && formik.errors.units
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.units === 'two'}
                    />
                  }
                  label="Two"
                />
                <FormControlLabel
                  value="three"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.units && formik.errors.units
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.units === 'three'}
                    />
                  }
                  label="Three"
                />
                <FormControlLabel
                  value="four"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.units && formik.errors.units
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.units === 'four'}
                    />
                  }
                  label="Four"
                />
                <FormControlLabel
                  value="five-plus"
                  control={
                    <Radio
                      sx={{
                        color:
                          formik.touched.units && formik.errors.units
                            ? 'error.main'
                            : 'unset',
                      }}
                      checked={formik.values.units === 'five-plus'}
                    />
                  }
                  label="Five or more"
                />
              </RadioGroup>
              {Boolean(formik.touched.units && formik.errors.units) && (
                <FormHelperText>
                  {formik.touched.units && formik.errors.units}
                </FormHelperText>
              )}
            </FormControl>
          )}
          {formik.values.claimType === 'non-priced-pop' && (
            <TextField
              fullWidth
              sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
              label="Total Units"
              name="unitsNonPriced"
              variant="outlined"
              placeholder="0"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={formik.values.unitsNonPriced}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.unitsNonPriced && formik.errors.unitsNonPriced
              )}
              helperText={
                formik.touched.unitsNonPriced && formik.errors.unitsNonPriced
              }
            />
          )}
        </Grid>
        {formik.values.claimType === 'non-pop' && (
          <>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography variant="body1">
                2. Please provide the following information:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Store Location
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
                label="Name"
                name="storeLocation.name"
                variant="outlined"
                placeholder="Store Name"
                value={formik.values?.storeLocation?.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched?.storeLocation?.name &&
                    formik.errors?.storeLocation?.name
                )}
                helperText={
                  formik.touched?.storeLocation?.name &&
                  formik.errors?.storeLocation?.name
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
                label="City"
                name="storeLocation.city"
                variant="outlined"
                placeholder="Red Bank"
                value={formik.values?.storeLocation?.city}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched?.storeLocation?.city &&
                    formik.errors?.storeLocation?.city
                )}
                helperText={
                  formik.touched?.storeLocation?.city &&
                  formik.errors?.storeLocation?.city
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                sx={{ mb: 3 }}
                label="State"
                name="storeLocation.state"
                variant="outlined"
                placeholder="NJ"
                value={formik.values?.storeLocation?.state}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched?.storeLocation?.state &&
                    formik.errors?.storeLocation?.state
                )}
                helperText={
                  formik.touched?.storeLocation?.state &&
                  formik.errors?.storeLocation?.state
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Approximate Purchase Date
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mb: 3 }}>
              <DateField
                fullWidth
                sx={{ pr: { xs: 0, md: 3 }, mb: 3 }}
                label="Purchase Date"
                name="purchaseDate"
                variant="outlined"
                placeholder="mm/dd/yyyy"
                inputProps={{
                  max: 10,
                }}
                value={formik.values.purchaseDate}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  formik.setFieldValue('purchaseDate', value);
                }}
                error={Boolean(
                  formik.touched.purchaseDate && formik.errors.purchaseDate
                )}
                helperText={
                  formik.touched.purchaseDate && formik.errors.purchaseDate
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography variant="h6">Retailer or Online Seller</Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mb: 3 }}>
              <FormControl
                component="fieldset"
                error={Boolean(
                  formik.touched.sellerType && formik.errors.sellerType
                )}
              >
                <FormLabel id="seller-type-radio-group">Seller Type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="seller-type-radio-group"
                  name="sellerType"
                  value={formik.values.sellerType}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="retailer"
                    control={
                      <Radio
                        sx={{
                          color:
                            formik.touched.sellerType &&
                            formik.errors.sellerType
                              ? 'error.main'
                              : 'unset',
                        }}
                        checked={formik.values.sellerType === 'retailer'}
                      />
                    }
                    label="Retailer"
                  />
                  <FormControlLabel
                    value="online-seller"
                    control={
                      <Radio
                        sx={{
                          color:
                            formik.touched.sellerType &&
                            formik.errors.sellerType
                              ? 'error.main'
                              : 'unset',
                        }}
                        checked={formik.values.sellerType === 'online-seller'}
                      />
                    }
                    label="Online Seller"
                  />
                </RadioGroup>
                {Boolean(
                  formik.touched.sellerType && formik.errors.sellerType
                ) && (
                  <FormHelperText>
                    {formik.touched.sellerType && formik.errors.sellerType}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        {['priced-pop', 'non-priced-pop'].includes(formik.values.claimType) && (
          <>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Typography variant="body">
                3. Please attach copies of your Proof(s) of purchase for each
                unit for which you are claiming reimbursement. Proofs of
                Purchase may not be re-submitted for additional reimbursement
                and may not be shared among or used by multiple Class Members.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <ImageUploader
                title="Proof of Purchase of the Product Upload"
                files={files}
                setFiles={setFiles}
                key={`fileUpload}`}
              />
            </Grid>
          </>
        )}
        {formik.values.claimType === 'priced-pop' && (
          <>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography variant="body">
                Please provide the total amount for all covered products that
                are depicted on the Proof of Purchase(s).
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} lg={3} sx={{ mb: 3 }}>
              <CurrencyField
                label="Total Price"
                id="paidAmount"
                name="paidAmount"
                fullWidth
                type="text"
                value={formik.values.paidAmount}
                onChange={(e) => {
                  formik.setFieldValue('paidAmount', e);
                }}
                variant="outlined"
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.paidAmount && formik.errors.paidAmount
                )}
                helperText={
                  formik.touched.paidAmount && formik.errors.paidAmount
                }
              />
            </Grid>
          </>
        )}
      </React.Fragment>
    ),
    [formik]
  );

  const generateCertificate = useCallback(
    () => (
      <React.Fragment>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <FormControl
            error={Boolean(formik.touched.certified && formik.errors.certified)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="certified"
                  checked={formik.values.certified}
                  onChange={formik.handleChange}
                  sx={{
                    color:
                      formik.touched.certified && formik.errors.certified
                        ? 'error.main'
                        : 'unset',
                  }}
                />
              }
              label={
                <p>
                  <strong>
                    I hereby certify the terms above, under penalty of perjury.
                  </strong>
                </p>
              }
            />
            {Boolean(formik.touched.certified && formik.errors.certified) && (
              <FormHelperText>
                {formik.touched.certified && formik.errors.certified}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Claimant Full Name"
            name="claimantFullName"
            variant="outlined"
            placeholder="First Last"
            value={formik.values.claimantFullName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.claimantFullName && formik.errors.claimantFullName
            )}
            helperText={
              formik.touched.claimantFullName && formik.errors.claimantFullName
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6} sx={{ mt: 3 }}>
          <DateField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={"Today's Date"}
            name="date"
            variant="outlined"
            placeholder="mm/dd/yyyy"
            value={formik.values.date}
            onBlur={formik.handleBlur}
            format="MM/DD/YYYY"
            onChange={(value) => {
              formik.setFieldValue('date', value);
            }}
            error={Boolean(formik.touched.date && formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider fullWidth />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                name="acceptPrivacy"
                checked={formik.values.acceptPrivacy}
                onChange={formik.handleChange}
                sx={{
                  color:
                    formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
                      ? 'error.main'
                      : 'unset',
                }}
              />
            }
            label={
              <Typography>
                By clicking “Submit Claim” you are confirming that you have read
                and agree to our <Link to="/privacy">Privacy Policy</Link>
              </Typography>
            }
          />
          {Boolean(
            formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
          ) && (
            <FormHelperText
              sx={{
                color:
                  formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
                    ? 'error.main'
                    : 'unset',
              }}
            >
              {formik.touched.acceptPrivacy && formik.errors.acceptPrivacy}
            </FormHelperText>
          )}
        </Grid>
      </React.Fragment>
    ),
    [formik]
  );

  return [
    generateForm,
    formik.handleSubmit,
    updateInitialValues,
    generateCertificate,
  ];
}
